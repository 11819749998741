import { css, styled } from 'styled-components';
import { Container } from '@aurora-components';
import { Sizes } from 'styles/constants';
import { mediaQueries } from 'styles/helpers';

export const ErrorContainer = styled(Container)`
  margin-top: 10rem;
  padding-top: 12rem;
  max-width: ${Sizes.maxWidth}px;
  margin: 0 auto;
`;

export const ErrorContent = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  width: 100%;
  max-width: 100%;
  padding: 0 12px;
  align-self: center;

  a {
    margin-top: 16px;
  }

  ${mediaQueries(
    'md',
    css`
      max-width: 60%;
    `
  )}
`;