'use client';

// Error components must be Client Components
import { useParams } from 'next/navigation';

import ErrorView from 'components/system/error-page/error-page';

export default function Error() {
  const { locale } = useParams() as { locale: string };

  return <ErrorView locale={locale} />;
}
