import { useTranslations } from 'next-intl';
import { Button, Column, Grid, Text } from '@aurora-components';
import { AltPeopleDoodle } from 'icons/components';
import { ErrorContainer, ErrorContent } from './error-page.styles';

type Props = {
  locale: string;
}

export default function ErrorView({ locale }: Props) {
  const t = useTranslations('error');

  return (
    <ErrorContainer $centered $maxWidth>
      <Grid>
        <Column $span={{ xs: 12 }}>
          <ErrorContent>
            <AltPeopleDoodle
              width={250}
              height={250}
            />
            <Text 
              $variant="h3"
              $textAlign="center"
              $color="primary-base100"
            >
              {t('title')}
            </Text>
            <Text
              $variant="p"
              $textAlign="center"
              $color="primary-base100"
            >
              {t('message')}
            </Text>
            <Button as="a" href={locale === 'en' ? '/en/' : '/'}>
              {t('label')}
            </Button>
          </ErrorContent>
        </Column>
      </Grid>
    </ErrorContainer>
  );
}
